import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	height: 100vh;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
`;
const Logo = styled.img`
	width: 100px;
	height: auto;
	object-fit: cover;
	margin-bottom: 1.5rem;
`;
const Title = styled.h1`
	font-family: bebasbold;
`;

class NewIndex extends React.Component {
	render() {
		return (
			<Container>
				<Logo src={require('../images/logo.png')} />
				<Title>We'll be back soon.</Title>
			</Container>
		);
	}
}

export default NewIndex;
